import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';
import { AuthService } from '../services/authService';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to fetch user data on initial load
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setLoading(true);
        const userData = await AuthService.getUser(); // Fetch user session
        setUser(userData);
        setIsAuthenticated(true);
      } catch (error) {
        console.warn('No active session found.');
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    initializeAuth();
  }, []);

  // Login function
  const login = useCallback(async (credentials) => {
    try {
      const userData = await AuthService.login(credentials);
      setUser(userData);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login failed:', error);
      throw error; // Handle errors in UI
    }
  }, []);

  // Logout function
  const logout = useCallback(async () => {
    try {
      await AuthService.logout();
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }, []);

  // Register function
  const register = useCallback(async (credentials) => {
    try {
      const userData = await AuthService.register(credentials);
      setUser(userData);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  }, []);

  const value = {
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    register,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Hook to access authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
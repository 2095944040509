import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './styles/chat.module.css';
import Header from './components/Header';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';
import { useChatMessages, useMessageStream } from './hooks';
import { sanitizeInput } from '../../utils/inputSanitizer';

function Chat() {
  const [input, setInput] = useState('');
  const [isSessionListVisible, setIsSessionListVisible] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const lastScrollHeight = useRef(0);
  
  const {
    messages,
    isLoading,
    setIsLoading,
    addMessage,
    updateLastMessage,
    clearMessages
  } = useChatMessages();
  
  const { processStream } = useMessageStream();

  const handleScroll = useCallback(() => {
    if (!messageListRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
    const bottom = scrollHeight - scrollTop - clientHeight;
    setIsAtBottom(bottom < 50);
    lastScrollHeight.current = scrollHeight;
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!messageListRef.current) return;
    
    const { scrollHeight, clientHeight } = messageListRef.current;
    
    // Only auto-scroll if we're at the bottom or it's a new message
    if (isAtBottom || scrollHeight > lastScrollHeight.current) {
      messageListRef.current.scrollTop = scrollHeight - clientHeight;
    }
    
    lastScrollHeight.current = scrollHeight;
  }, [isAtBottom]);

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const toggleSessionList = () => {
    setIsSessionListVisible((prev) => !prev);
  };

  const startNewSession = () => {
    clearMessages();
    setInput('');
    setIsLoading(false);
  };

  // Update handleSubmit with sanitization
  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedInput = sanitizeInput(input);
    
    if (!sanitizedInput || isLoading) return;

    setIsAtBottom(true); // Force scroll to bottom on new message
    
    const userMessage = { 
      content: sanitizedInput, 
      role: 'user' 
    };
    const assistantMessage = { 
      content: '', 
      role: 'assistant' 
    };
    
    addMessage(userMessage);
    addMessage(assistantMessage);
    setInput('');
    setIsLoading(true);

    try {
      const response = await fetch('/api/v1/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: sanitizedInput,
          timestamp: Date.now(),
          messageId: crypto.randomUUID()
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await processStream(
        response,
        (content) => {
          updateLastMessage(msg => ({
            ...msg,
            content: msg.content + content,
            id: Date.now()
          }));
        },
        (error) => {
          updateLastMessage(() => ({
            role: 'assistant',
            content: `Error: ${error}`,
            id: Date.now(),
            isError: true
          }));
        }
      );
    } catch (error) {
      console.error('Error:', error);
      updateLastMessage(() => ({
        role: 'assistant',
        content: 'Error: Failed to get response',
        id: Date.now(),
        isError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header 
        toggleSessionList={toggleSessionList} 
        startNewSession={startNewSession} 
        isSessionListVisible={isSessionListVisible}
      />
      <MessageList 
        messages={messages}
        isLoading={isLoading}
        messagesEndRef={messagesEndRef}
        messageListRef={messageListRef}
      />
      <MessageInput 
        input={input}
        setInput={setInput}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Chat; 
export class AuthService {
  static async login(credentials) {
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error('Invalid login credentials');
    }

    return response.json(); // Assumes response contains user data
  }

  static async logout() {
    const response = await fetch('/api/auth/logout', {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Logout failed');
    }
  }

  static async register(credentials) {
    const response = await fetch('/api/auth/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error('Registration failed');
    }

    return response.json(); // Assumes response contains user data
  }

  static async getUser() {
    const response = await fetch('/api/auth/user', {
      method: 'GET',
      credentials: 'include', // Include cookies for session validation
    });

    if (!response.ok) {
      throw new Error('No active session found');
    }

    return response.json();
  }
}